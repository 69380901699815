<template>
    <div>
        <!-- <vs-button
            class="rounded-full"
            :color="route === '/app/users/list' ? 'primary' : 'white'"
            :class="route === '/app/users/list' ? 'text-white' : 'text-primary'"
            @click="toggleRoute()"
        >
            <feather-icon icon="PlusIcon" class="inline-block align-middle"></feather-icon>
            {{ this.$t('Crear usuario') }}
        </vs-button> -->

        <div class="mt-2">
            <router-view></router-view>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    created() {
        this.$store.dispatch('updatePageTitle', {
            title: this.$t('Clientes'),
            subtitle: this.$t('Vizualice aquí la información de sus clientes'),
        });
    },

    methods : {
        toggleRoute() {
            if( this.route === '/app/clients/list' ) this.$router.push({ path : '/app/clients/new' });
            else this.$router.push({ path : '/app/clients/list' });
        },
    },
    
    computed : {
        route() {
            return this.$route.path;
        }
    }
})
</script>
